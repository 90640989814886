<template>
    <div class="container">

        <div class="serverList">
            <div class="item" :class="{active:serverActive?serverActive.id==item.id:false}"
            v-for="(item,index) in serverList" :key="index" @click="serverActive=item">
                <div class="name">{{item.frp_server_name}}</div>
                <div class="des">{{item.description}}</div>
                <!-- {{item.frp_server_domain}} -->
            </div>
        </div>

        <div class="commonList">
            <el-input class="commonSearch" placeholder="根据用户ID、协议内容搜索" v-model="searchKey" clearable>
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <div class="statisticsSearch" v-if="statisticalData">
                <div class="model pay">
                    <el-radio-group v-model="query.payStatus">
                        <el-radio label="0">所有协议: ({{statisticalData.all}}个)</el-radio>
                        <el-radio label="1">付费协议: {{statisticalData.payCount}}个({{statisticalData.payCount/(statisticalData.all) * 100 | toFixed}}%)</el-radio>
                        <el-radio label="2">免费协议: {{statisticalData.freeCount}}个({{statisticalData.freeCount/(statisticalData.all) * 100 | toFixed}}%)</el-radio>
                    </el-radio-group>
                </div>
                <div class="model protocol">
                    <el-radio-group v-model="query.type">
                        <el-radio label="all">所有协议({{statisticalData.all}})</el-radio>
                        <el-radio label="http">http连接：{{statisticalData.httpCount}}个({{statisticalData.httpCount/statisticalData.all * 100 | toFixed}}%)</el-radio>
                        <el-radio label="https">https连接：{{statisticalData.httpsCount}}个({{statisticalData.httpsCount/statisticalData.all * 100 | toFixed}}%)</el-radio>
                        <el-radio label="ssh">ssh连接：{{statisticalData.sshCount}}个({{statisticalData.sshCount/statisticalData.all * 100 | toFixed}}%)</el-radio>
                        <el-radio label="tcp">tcp连接：{{statisticalData.tcpCount}}个({{statisticalData.tcpCount/statisticalData.all * 100 | toFixed}}%)</el-radio>
                        <el-radio label="udp">udp连接：{{statisticalData.udpCount}}个({{statisticalData.udpCount/statisticalData.all * 100 | toFixed}}%)</el-radio>
                    </el-radio-group>
                </div>
                <div class="model port">
                    <el-radio-group disabled v-model="query.port">
                        <el-radio label="0">所有端口({{statisticalData.all}}个)</el-radio>
                        <el-radio label="1">已使用端口:{{statisticalData.usedCount}}个({{statisticalData.usedCount/(statisticalData.idleCount + statisticalData.occupiedCount + statisticalData.usedCount) * 100 | toFixed}}%)</el-radio>
                        <el-radio label="2">被其它进程占用:{{statisticalData.occupiedCount}}个({{statisticalData.occupiedCount/(statisticalData.idleCount + statisticalData.occupiedCount + statisticalData.usedCount) * 100 | toFixed}}%)</el-radio>
                        <el-radio label="3">空闲端口:{{statisticalData.idleCount}}个({{statisticalData.idleCount/(statisticalData.idleCount + statisticalData.occupiedCount + statisticalData.usedCount) * 100 | toFixed}}%)</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <table v-show="dataList.length>0" v-loading="isLoading">
                <thead>
                    <tr>
                        <td>用户ID</td>
                        <td>协议ID</td>
                        <td>协议名称</td>
                        <td>协议类型</td>
                        <td>占用端口</td>
                        <td>状态</td>
                        <td>创建时间</td>
                        <td>过期时间</td>
                        <td>剩余有效时间</td>
                        <td>创建时间</td>
                        <td>更新时间</td>
                        <td>协议内容</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in dataList" :key="index">
                        <td>
                            <a :href="'https://www.sidoc.cn/user/' + item.user_id" target="_blank">{{item.user_id}}</a>
                        </td>
                        <td>{{item.id}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.type}}</td>
                        <td>{{item.port}}</td>
                        <td>
                            <span v-if="item.is_enable==1" style="color:green;">启用</span>
                            <span v-else style="color: red;">停用</span>
                        </td>
                        <td>{{item.create_time}}</td>
                        <td>{{item.expiration_time}}</td>
                        <td>{{item.expiration_time | getExpirationTime}}</td>
                        <td>{{item.create_time}}</td>
                        <td>{{item.update_time}}</td>
                        <td>
                            <el-link :underline="false" type="primary" @click="selectedItem=item;dialogVisible=true;">详情</el-link>
                        </td>
                    </tr>
                </tbody>
            </table>

            <el-pagination
                background
                @current-change="loadData"
                :current-page.sync="currentPage"
                :page-size="pageSize"
                layout="total, prev, pager, next"
                :total="total"
                style="text-align: right;margin-top: 10px;">
            </el-pagination>
        </div>

        <!-- 显示协议内容 -->
        <el-dialog v-if="selectedItem" :title="selectedItem.name" :visible.sync="dialogVisible" width="30%">
           <pre>{{selectedItem.content}}</pre>
        </el-dialog>

    </div>
</template>

<script>

export default {
    data(){
        return {
            serverList:[],
            serverActive:null,

            query:{  // 查询条件
                payStatus:"0",
                type:"all",
                port:"0"
            },
            dataList:[],
            searchKey:"",
            pageSize:14,
            currentPage:1,
            total:0,
            selectedItem:null, // 当前选中项
            dialogVisible:false,

            isLoading:false,

            statisticalData:null, // 统计数据
        }
    },
    mounted(){
        this.loadServerData();
    },
    methods:{
        // 加载frp服务器列表
        loadServerData(){
            let that = this;
            this.isLoading = true;
            this.$api.request({
                url: process.env.VUE_APP_BASE_URL_FRP_CENTER + '/serverList',
                method: 'post',
            }).then(res => {
                if(res.status_code == 0){
                    that.serverList = res.data;
                }
            }).finally(()=>{
               that.isLoading = false;
            });
        },
        // 加载协议数据
        loadData(currentPage){
            let that = this;
            this.isLoading = true;
            let params = {
                "currentPage":currentPage,
                "pageSize":this.pageSize,
                "searchKey":this.searchKey,
                "payStatus":this.query.payStatus,
                "type":this.query.type,
                "port":this.query.port
            };
            this.$api.request({
                url: "https://" + this.serverActive.frp_server_domain + '/sys/protocolList',
                data:params,
                method: 'post',
            }).then(res => {
                if(res.code == 0){
                    that.dataList = res.data.list;
                    that.total = res.data.count;
                    that.dataList.forEach((item)=>{
                        item.content = JSON.parse(item.content);
                    });
                }
            }).finally(()=>{
               that.isLoading = false;
            });
        },
        // 加载统计数据
        loadStatisticalData(){
            let that = this;
            this.$api.request({
                url: "https://" + this.serverActive.frp_server_domain + '/sys/statisticalData',
                method: 'post',
            }).then(res => {
                if(res.code == 0){
                    res.data.all = res.data.freeCount + res.data.payCount;
                    that.statisticalData = Object.assign({},res.data);        
                }
            });
        }
    },
    watch:{
        serverActive(){
            this.currentPage = 1;
            this.loadData(this.currentPage);
            this.loadStatisticalData();
        },
        searchKey(){
            this.currentPage = 1;
            this.loadData(this.currentPage);
        },
        "query":{
            deep:true,
            handler(from,to){
                this.currentPage = 1;
                this.loadData(this.currentPage);
            }
        }
    }
}
</script>

<style scoped>

.container{
    display:flex;
    flex-direction:row;
}
.serverList{
    width:180px;
    border-right: 1px solid rgb(201, 201, 201);
}
.serverList>.item{
    border-bottom: 1px solid rgb(235, 235, 235);
    padding: 8px 14px;
    cursor: pointer;
}
.serverList>.item:hover{
    background:rgba(242, 242, 242, 0.495);
}
.serverList>.item.active{
    background:rgb(242, 242, 242);
}
.serverList>.item>.name{
    font-size: 16px;
    font-weight: bold;
}
.serverList>.item>.des{
    font-size: 14px;
    color: gray;
}

.commonList{
    flex-grow: 1;
}


/* 统计 */
.statisticsSearch{
    display:flex;
    flex-direction: column;
    margin: 8px 0px;
    padding-left: 22px;
}
.statisticsSearch>.model{
    display:flex;
    width:100%;
    margin-top: 4px;
    padding:4px 10px;
}
.statisticsSearch>.model label{
    min-width:160px;
}

</style>